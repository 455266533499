export const GOV_PAGE_MODAL = {
  NULL: null,
  TRANSACTION_SUCCESS: 'transaction-success',
  TRANSACTION_FAILED: 'transaction-failed',
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  ABSTAIN: 'Abstain',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  ABSTAINED: 'Abstained',
  END_DATE: 19,
  END_HOUR: 13,
  END_MIN: 5,
  ACCEPT_VOTE: 1,
  REJECT_VOTE: 2,
  ABSTAINED_VOTE: 3,
};
